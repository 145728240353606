import {
  LOGOUT,
  GLOBAL_ERROR,
  FROM_URL,
  VALID_ACCESS,
  BREADCRUMBS,
  CURRENT_CLIENT_NAME,
  CURRENT_CLIENT_DOMAIN,
  CAS_API_URL,
  PROJECT,
  SIDEBAR_ACTIVE,
  LOADING,
  ORGANISATION,
  IH_OMS_API_URL,
  SHOW_OFFLINE,
  HIDE_OFFLINE,
  DIR_NAME,
  BUCKET_NAME,
  DOCUMENT_HELPER_API_URL,
  BUCKET_ORIGIN,
  AUTH_BFF_API_URL,
} from "../constant";
import history from "../history";
import customHttp from "../custom_http";
import { getMapSessionToken } from "./map_session_token_generator";

export function validateAndSetClient(id) {
  const url = `${CAS_API_URL}/api/v1/orgs/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: CURRENT_CLIENT_DOMAIN,
          payload: response.data.data.org.domain,
        });
        dispatch({
          type: CURRENT_CLIENT_NAME,
          payload: response.data.data.org.name,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getOrg(id) {
  const url = `${CAS_API_URL}/api/v1/orgs/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: ORGANISATION,
          payload: response.data.data.org,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export const validateOrg = (domain) => {
  const url = `${CAS_API_URL}/api/v1/orgs/${domain}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        return { status: "errorStatus", message: error };
      });
};

export function getClientCategories() {
  const url = `${IH_OMS_API_URL}/clients/api/v1/client_categories/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: {
      conditions: [{}],
      limit: 100,
    },
    withCredentials: false,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function handleLogoutError(error, dispatch) {
  if (error.response && error.response.status === 401) {
    dispatch({ type: LOGOUT, payload: null });
    dispatch({
      type: GLOBAL_ERROR,
      payload: "You have been logged out, Please SignIn again",
    });
    history.push("/");
  }
  if (error.response && error.response.status === 403) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: "You are not authorized to access this, please try again",
    });
  }
  return null;
}

export function clearProject() {
  return (dispatch) => {
    dispatch({ type: PROJECT, payload: {} });
    return { status: "successStatus", message: "" };
  };
}

export function setFromUrl(url) {
  return (dispatch) => {
    dispatch({ type: FROM_URL, payload: url });
  };
}

export function setGlobalError(message) {
  return (dispatch) => {
    dispatch({ type: GLOBAL_ERROR, payload: message });
  };
}

export function setBreadcrumbs(data) {
  return (dispatch) => {
    dispatch({ type: BREADCRUMBS, payload: data });
  };
}

export function checkValidAccess(data) {
  return (dispatch) => {
    dispatch({ type: VALID_ACCESS, payload: data });
  };
}

export function setCurrentClientName(data) {
  return (dispatch) => {
    dispatch({ type: CURRENT_CLIENT_NAME, payload: data });
  };
}

export function setSidebarActiveState(data) {
  return (dispatch) => {
    dispatch({ type: SIDEBAR_ACTIVE, payload: data });
  };
}

export function setLoader(value) {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: value });
  };
}

export function showOffline(type, msg, delay) {
  return (dispatch) => {
    dispatch({ type: SHOW_OFFLINE, payload: { type, msg, delay } });
  };
}

export function hideOffline() {
  return (dispatch) => {
    dispatch({ type: HIDE_OFFLINE, payload: null });
  };
}

export const getS3PresignedURL = async (payload) => {
  const url = `${DOCUMENT_HELPER_API_URL}/api/v1/aws/s3/presigned-url/put-object`;
  const request = await customHttp({
    url,
    method: "post",
    data: payload,
  });
  return request;
};

export const updateACL = async (payload) => {
  const url = `${DOCUMENT_HELPER_API_URL}/api/v1/aws/s3/presigned-url/update-acl`;
  const request = await customHttp({
    url,
    method: "post",
    data: payload,
  });
  return request;
};

export const uploadToS3 = (file) =>
  new Promise((resolve, reject) => {
    const upload = async () => {
      const fileName = file.name?.replace(/[^a-zA-Z0-9.-]/g, "");
      const s3PreSignedURLPayload = { file_type: file.type, file_path: `${DIR_NAME}/${Date.now().toString()}-${fileName}`, bucket: BUCKET_NAME };
      const s3PreSignedURLResponse = await getS3PresignedURL(s3PreSignedURLPayload);
      if (s3PreSignedURLResponse.status === 200) {
        const s3PreSignedURLJSON = s3PreSignedURLResponse.data;
        await fetch(s3PreSignedURLJSON.data, { method: "PUT", body: file, headers: { ContentType: file.type } });
        const updateACLPayload = { acl: "public-read", file_path: s3PreSignedURLPayload.file_path, bucket: BUCKET_NAME };
        const updateACLResponse = await updateACL(updateACLPayload);
        if (updateACLResponse.status === 200) {
          resolve({
            message: "file uploaded",
            url: `${BUCKET_ORIGIN}/${s3PreSignedURLPayload.file_path}`,
          });
        } else {
          reject(new Error("failed to upload"));
        }
      }
      reject(new Error("failed to upload"));
    };
    upload();
  });

export const getLocationFromLatLong = async (lat, lng) => {
  const url = `${AUTH_BFF_API_URL}/api/v1/map/get_area?latitude=${lat}&longitude=${lng}`;
  const request = await customHttp({
    url,
    method: "get",
  });
  return request;
};

export const getPlaces = async (search_term) => {
  const url = `${AUTH_BFF_API_URL}/api/v1/map/places_autocomplete?search_term=${search_term}`;
  const mapSessionToken = getMapSessionToken();
  const request = await customHttp({
    url,
    method: "POST",
    data: JSON.stringify({}),
    headers: { "x-map-session-token": mapSessionToken },
  });
  return request;
};

export const getLatLongFromLocation = async (place_id) => {
  const url = `${AUTH_BFF_API_URL}/api/v1/map/place_details?place_id=${place_id}`;
  const mapSessionToken = getMapSessionToken();
  const request = await customHttp({
    url,
    method: "get",
    headers: { "x-map-session-token": mapSessionToken },
  });
  return request;
};
