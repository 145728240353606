import { uuidv4 } from "../components/shared/custom_html_elements/utils";

export const generateMapSessionToken = () => {
  const sessionToken = uuidv4();
  sessionStorage.setItem("mapSessionToken", sessionToken);
  return sessionToken;
};
export const getMapSessionToken = () => {
  if (!sessionStorage.getItem("mapSessionToken")) {
    return generateMapSessionToken();
  }
  return sessionStorage.getItem("mapSessionToken");
};
